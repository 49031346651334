<template>
  <div class="error-page">
    <p class="title">抱歉，您访问的页面出错了</p>
    <span class="small">您可能输错了网址，或该网页已被删除、不存在等</span>
    <el-button plain @click="goHome">返回首页</el-button>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "404",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
    })
    const goHome = () => {
      router.replace('/');
    }
    return {
      state,
      goHome,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.error-page{
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title{
    margin-top: 20px;
    font-size: 24px;
    color: #282828;
  }
  .small{
    margin: 12px 0 24px;
    font-size: 14px;
    color: #969696;
  }
}
</style>